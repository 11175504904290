@layer modules, ui, base;
@layer base {
  .BlogCard_card__5FUhZ {
  position: relative;
}

.BlogCard_cardImageWrapper__sYtBP {
  display: block;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: var(--radius-200);
}

.BlogCard_cardImage__NwBZw {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.BlogCard_category__ilRy3:not(:first-child) {
  margin-top: var(--spacer-200);
}

.BlogCard_title__Entrj:not(:first-child) {
  margin-top: var(--spacer-200);
}

.BlogCard_cardLink__uQLvH {
  margin-top: var(--spacer-200);
  color: var(--color-black);
  text-decoration: none;
}

.BlogCard_summary__gnbSm {
  margin: var(--spacer-100) 0 0;
}

/* TYPE: ARTICLE */
.BlogCard_card--article__w6fAs .BlogCard_cardLink__uQLvH::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* TYPE: GUIDE */
.BlogCard_card--guide__X_YOD {
  padding: var(--spacer-200);
  border: 1px solid var(--color-grey--100);
  border-radius: var(--radius-200);
}

@media (min-width: 64rem) {
  /* 2 COLUMNS */
  .BlogCard_card--2cols__7N2p0 {
    display: grid;
    gap: var(--spacer-100) var(--spacer-200);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_cardImageWrapper__sYtBP {
    grid-column: 1;
    grid-row: 1/5;
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_title__Entrj {
    grid-column: 2;
    grid-row: 2/5;
    margin: 0;
    font-size: var(--body);
    line-height: var(--bodyLineHeight);
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_cardLink__uQLvH {
    margin: 0;
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_category__ilRy3 {
    grid-column: 2;
    grid-row: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0 !important;
  }
}

}
@layer base {
  .BlogIndex_main__8tq5H {
  overflow: hidden;
}

.BlogIndex_headerWrapper__WJHii {
  position: relative;
}

.BlogIndex_headerWrapper__WJHii::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 10px;
  right: -110px;
  width: 211px;
  height: 193px;
  background-image: var(--backgroundImage);
  background-size: cover;
  rotate: 45deg;
}

.BlogIndex_title__2qMSg {
  margin-bottom: var(--spacer-200);
}

.BlogIndex_filters__EHTk6 {
  position: relative;
  /* TODO: what is the current stacking context? */
  z-index: var(--z-up-in-the-current-stacking-context); /* to override some z-index inside Cards, below the FilterBar */
}

.BlogIndex_image__XaG5A {
  width: 100%;
  height: auto;
}

.BlogIndex_cover__RDFTQ {
  display: block;
}

.BlogIndex_relatedCard__Wd0ZF {
  margin-top: var(--spacer-400);
  margin-bottom: var(--spacer-400);
}

.BlogIndex_list__dbUEE {
  gap: var(--spacer-400);
  margin-top: var(--spacer-600);
}

@media (max-width: 63.999rem) {
  .BlogIndex_headerWrapper__WJHii::before {
    display: none;
  }
}

@media (min-width: 48rem) {
  .BlogIndex_headerWrapper__WJHii {
    display: grid;
    gap: var(--spacer-200);
    grid-template-columns: 2fr 33%;
  }
}

@media (min-width: 64rem) {
  .BlogIndex_headerWrapper__WJHii {
    grid-template-columns: 4fr 20%;
  }

  .BlogIndex_list__dbUEE:not(.BlogIndex_list--emphasize__fNrOm) {
    gap: var(--spacer-600) var(--spacer-700);
    grid-template-rows: masonry;
  }

  .BlogIndex_list--emphasize__fNrOm {
    grid-template-columns: 3fr 2fr;
  }

  .BlogIndex_list--emphasize__fNrOm > :first-child {
    grid-row: 1/4;
  }
}

}

@layer base {
  .main {
  overflow: hidden;
}

.headerWrapper {
  position: relative;
}

.headerWrapper::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 10px;
  right: -110px;
  width: 211px;
  height: 193px;
  background-image: var(--backgroundImage);
  background-size: cover;
  rotate: 45deg;
}

.title {
  margin-bottom: var(--spacer-200);
}

.filters {
  position: relative;
  /* TODO: what is the current stacking context? */
  z-index: var(--z-up-in-the-current-stacking-context); /* to override some z-index inside Cards, below the FilterBar */
}

.image {
  width: 100%;
  height: auto;
}

.cover {
  display: block;
}

.relatedCard {
  margin-top: var(--spacer-400);
  margin-bottom: var(--spacer-400);
}

.list {
  gap: var(--spacer-400);
  margin-top: var(--spacer-600);
}

@media (--medium-and-less-only) {
  .headerWrapper::before {
    display: none;
  }
}

@media (--medium-viewport) {
  .headerWrapper {
    display: grid;
    gap: var(--spacer-200);
    grid-template-columns: 2fr 33%;
  }
}

@media (--large-viewport) {
  .headerWrapper {
    grid-template-columns: 4fr 20%;
  }

  .list:not(.list--emphasize) {
    gap: var(--spacer-600) var(--spacer-700);
    grid-template-rows: masonry;
  }

  .list--emphasize {
    grid-template-columns: 3fr 2fr;
  }

  .list--emphasize > :first-child {
    grid-row: 1/4;
  }
}

}